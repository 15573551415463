/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
/* You can add global styles to this file, and also import other style files */
@import "./theme/variables";
@import "./theme/helper-classes";
@import "./theme/material";
@import "./theme/main";
@import "./theme/disco-variables.scss";

@font-face {
    font-family: "FontAwesome";
    src:
        url("../assets/font/fontawesome-webfont.ttf") format("truetype"),
        url("../assets/font/fontawesome-webfont.woff2") format("woff2"),
        url("../assets/font/fontawesome-webfont.woff") format("woff"),
        url("../assets/font/fontawesome-webfont.eot"),
        url("../assets/font/fontawesome-webfont.svg") format("svg");
    font-size: 18px;
    font-weight: bold;
}

:root .light {
    --buttons-color: white;
    --horizontal-facet-button: #1580b299;
}

.auth-account.mat-option {
    overflow: unset !important;
    height: unset !important;
    min-height: 48px !important;

    .mat-option-text {
        height: 100%;
        display: flex !important;
        overflow: unset !important;
        justify-content: flex-start;

        .accuont-admins {
            font-size: 12px;
            line-height: 14px;
            white-space: normal;
            display: flex;
            align-items: center;
            padding: 10px 0;
            margin-left: 4px;
        }
    }
}

.options-wrapper {
    min-width: 368px !important;

    .mat-option {
        word-wrap: break-word;
        white-space: pre-wrap;
        line-height: initial !important;
        height: unset !important;
        max-height: unset;
        padding: 12px;
    }
}

.bol-item {
    min-width: 504px;
}

.algin-toggle-end {
    text-align: end;
}

.custom-snackbar {
    color: var(--main-color);
    background: var(--dark-500);

    .mat-simple-snackbar-action {
        color: white !important;
    }
}

.notifications-menu {
    max-height: 380px !important;
    min-width: 285px !important;
}

.auto-complete-wrapper {
    align-items: flex-start;
}

.select-wrapper {
    align-items: center;
}

.user-actions {

    button:nth-of-type(2),
    button:nth-of-type(3),
    button:nth-of-type(4) {
        display: none !important;
    }

    mat-divider:nth-of-type(1) {
        display: none !important;
    }
}

stat-action {
    display: none;
}

.repeated-sec {
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 10px;

    .item-group {
        display: flex;
    }

    formly-repeat-section .flex-row {
        min-height: 100px;
    }
}

.lot-repeated-sec {
    padding: 12px;
    border: solid 1px #ccd5e1;
    border-radius: 10px;
    margin-bottom: 10px;

    .item-group {
        display: flex;
    }

    formly-repeat-section .flex-row {
        min-height: 100px;
    }
}

.pad-wrapper .k-grid {
    height: calc(90vh - 200px);
}

.item-card {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    margin: 20px 0px;
    border-radius: 25px;
    padding: 30px 10px;
    background: white;
}

.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
    border-radius: 0 !important;

    &.mat-tab-label-active {
        border-bottom: solid 1px;
    }
}

.auto-complete-wrapper {
    .clear-button {
        top: 0 !important;
        right: 0 !important;
    }
}

.k-page-break {
    page-break-after: always;
    display: block;
    height: 0;
    /* Ensure it doesn't affect the layout visually */
}

// .main-workspace-content {
//     background-color: var(--base-color-light);

//     .main-view-container {
//         background-color: var(--base-color-light) !important;

//         .workspace-container {
//             background-color: var(--base-color-light) !important;

//             .third-row {
//                 height: 138px;
//                 align-items: flex-start !important;

//                 .search-bar-custom {
//                     width: calc(100vw - 90px);

//                     .mat-form-field-infix {
//                         margin-left: 20px;
//                     }

//                     .close-button {
//                         left: 6px;
//                         color: #afacac;
//                     }

//                     .mat-form-field-flex {
//                         background: transparent !important;
//                         border: none;
//                         box-shadow: none;
//                         border-bottom: solid 1px lightgray;
//                         border-radius: 0;
//                     }
//                 }

//                 .right {
//                     margin-top: 46px;
//                 }

//                 .filter-button {
//                     position: absolute;
//                     right: 0;
//                     margin-top: 30px;

//                     .mat-button-wrapper {
//                         span {
//                             display: none;
//                         }

//                     }
//                 }

//                 applied-filters-list {
//                     position: absolute;
//                     top: 138px;
//                     left: 20px;
//                 }
//             }

//             .scrollable-area {
//                 border-radius: 20px;
//                 overflow: hidden;
//             }

//             kendo-table-view {

//                 #discov-table-view {
//                     .k-grid {

//                         .k-grid-header-wrap,
//                         .k-grid-header,
//                         .k-grid-table-wrap {
//                             background-color: var(--main-color);
//                         }

//                         .k-grid-table {
//                             background-color: var(--main-color);
//                         }

//                         th {
//                             border-width: 0;
//                         }

//                         .k-table-thead {
//                             .k-table-row {
//                                 background-color: var(--main-color);

//                                 .k-column-title {
//                                     color: var(--base-color);
//                                     font-weight: bold;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//         }
//     }

// }

// formly-field mat-label {
//     color: var(--base-color);
//     font-weight: bold;
// }

// .base-color {
//     color: var(--base-color);
// }

// .applied-pinned-filters {
//     .filter-group .top-label {
//         background-color: var(--main-color) !important;

//         .list-button .menu-button {
//             align-items: flex-start;
//         }
//     }
// }
